<template>
   <div>
      <Settings />
   </div>
</template>

<script>
import Settings from "../components/Settings/Settings.vue";

export default {
   components: {
      Settings,
   },
};
</script>

<style></style>
