<template>
   <div>
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Dialog message="¿Desea guardar la configuración?" @result_ok="saveConfirm()" ref="save" />
      <v-toolbar color="secondary">
         Configuración
         <v-spacer></v-spacer>
         <v-btn icon small @click="reload()" class="mr-2"><v-icon small>mdi-reload</v-icon></v-btn>
         <v-btn @click="$refs.save.Show()"><v-icon small left>mdi-floppy</v-icon>Guardar</v-btn>
      </v-toolbar>
      <v-row class="ma-2">
         <v-col cols="12" sm="6" md="4" v-for="(item, index) in items" :key="index">
            <v-card>
               <v-card-title>{{ item.name }}</v-card-title>
               <v-card-text>
                  <div v-if="item.values">
                     <v-select v-model="item.value" :items="item.values" item-text="text" item-value="value" @input="item.edited = true"></v-select>
                  </div>
                  <div v-else>
                     <div v-if="item.password">
                        <v-text-field
                           v-model="item.value"
                           :type="item.password.show ? 'text' : 'password'"
                           :append-icon="item.password.show ? 'mdi-eye' : 'mdi-eye-off'"
                           @click:append="item.password.show = !item.password.show"
                           @input="item.edited = true"
                        ></v-text-field>
                     </div>
                     <div v-else>
                        <v-text-field v-model="item.value" @input="item.edited = true"></v-text-field>
                     </div>
                  </div>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </div>
</template>

<script>
const defaultSettings = () => {
   return [
      {
         edited: false,
         key: "pegaso_username",
         value: "",
         name: "Nombre de Usuario Pegaso",
      },
      {
         edited: false,
         key: "pegaso_password",
         value: "",
         name: "Contraseña Pegaso",
         password: {
            show: false,
         },
      },
      {
         edited: false,
         key: "pegaso_type",
         value: "",
         name: "Tipo Mensaje Pegaso",
         values: [
            { value: "S", text: "SMS" },
            { value: "F", text: "FLASH" },
         ],
      },
   ];
};

export default {
   methods: {
      load: async function () {
         try {
            this.isloading = true;
            await this.$store.dispatch("getConfig");
            this.$store.state.settings.forEach((element) => {
               this.items.forEach((el) => {
                  if (element.nombre == el.key) {
                     el.value = element.value;
                  }
               });
            });
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      saveConfirm: async function () {
         try {
            this.isloading = true;
            this.items.forEach(async (el) => {
               if (el.edited) {
                  await this.$store.dispatch("saveConfig", {
                     nombre: el.key,
                     value: el.value,
                  });
               }
            });
            this.reload;
            this.$refs.ok_noti.Show();
         } catch (error) {
         } finally {
            this.isloading = false;
         }
      },
      reload: function () {
         this.items = defaultSettings();
         this.load();
      },
   },
   mounted() {
      this.load();
   },
   data: () => {
      return {
         isloading: false,
         items: defaultSettings(),
      };
   },
};
</script>
